<template>
  <el-dialog v-if="dataInfo" :title="title" @closed="_closed" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="top5" top="5vh" width="80%">
    <el-form ref="dataInfo" class="padding-mini" :model="dataInfo" :rules="dataRules" label-width="100px">
      <el-col :span="12" class="padding-right-mini">
        <el-card class="margin-bottom-mini">
          <div slot="header">项目信息</div>
          <el-form-item label="项目分类">
            <p class="text-blue" v-if="dataInfo.projectType == 'duty'">管理类</p>
            <p class="text-purple" v-if="dataInfo.projectType == 'asset'">资产类</p>
            <p class="text-purple" v-if="dataInfo.projectType == 'item'">临时类</p>
          </el-form-item>
          <el-form-item label="项目名称" prop="projectName">
            <el-input v-model="dataInfo.projectName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="潜在风险" prop="riskDesc">
            <el-input v-model="dataInfo.riskDesc" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="区域编号" prop="areaCode">
            <el-input v-model="dataInfo.areaCode" autocomplete="off"></el-input>
          </el-form-item>
        </el-card>
        <el-card>
          <div slot="header">风险分析</div>
          <div class="twice">
            <el-form-item label="风险状态">
              <el-radio-group v-model="dataInfo.riskState">
                <el-radio-button label="正常"></el-radio-button>
                <el-radio-button label="异常"></el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="风险时态">
              <el-radio-group v-model="dataInfo.riskTimeState">
                <el-radio-button label="过去"></el-radio-button>
                <el-radio-button label="现在"></el-radio-button>
                <el-radio-button label="将来"></el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="危险因素分类" prop="riskFactorCode" style="width:100%">
              <el-radio-group v-model="dataInfo.riskFactorCode">
                <el-radio-button v-for="item in $constant.safelyMatter_riskFactorCode" :key="item.code" :label="item.code">{{ item.text }}</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="分析类别" style="width:100%">
              <el-radio-group v-model="dataInfo.analysisType" @change="analysisTypeChange">
                <el-radio-button :label="0">定性分析</el-radio-button>
                <el-radio-button :label="1">定量分析(LEC)</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="危险程度" v-if="dataInfo.analysisType === 0" style="width:100%">
              <el-radio-group v-model="dataInfo.riskLevel">
                <el-radio-button :label="1">不可接受风险</el-radio-button>
                <el-radio-button :label="2">重大风险</el-radio-button>
                <el-radio-button :label="3">中度风险</el-radio-button>
                <el-radio-button :label="4">可接受风险</el-radio-button>
                <el-radio-button :label="5">可忽略风险</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="危险程度" v-if="dataInfo.analysisType === 1" style="width:100%">
              <el-radio-group v-model="dataInfo.riskLevel" disabled>
                <el-radio-button :label="1">不可接受风险</el-radio-button>
                <el-radio-button :label="2">重大风险</el-radio-button>
                <el-radio-button :label="3">中度风险</el-radio-button>
                <el-radio-button :label="4">可接受风险</el-radio-button>
                <el-radio-button :label="5">可忽略风险</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="L值" prop="lec_l" v-if="dataInfo.analysisType === 1">
              <el-input-number v-model="dataInfo.lec_l" :min="0" @change="calcLec"></el-input-number>
            </el-form-item>
            <el-form-item label="E值" prop="lec_e" v-if="dataInfo.analysisType === 1">
              <el-input-number v-model="dataInfo.lec_e" :min="0" @change="calcLec"></el-input-number>
            </el-form-item>
            <el-form-item label="C值" prop="lec_c" v-if="dataInfo.analysisType === 1">
              <el-input-number v-model="dataInfo.lec_c" :min="0" @change="calcLec"></el-input-number>
            </el-form-item>
            <el-form-item label="LEC值" prop="lec" v-if="dataInfo.analysisType === 1">
              <el-input v-model="dataInfo.lec" autocomplete="off" disabled></el-input>
            </el-form-item>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card>
          <div slot="header">风险措施</div>
          <div class="twice">
            <el-form-item label="管控要求" prop="requirement" style="width:100%">
              <el-input type="textarea" rows="4" placeholder="请输入管控要求" v-model="dataInfo.requirement"> </el-input>
            </el-form-item>
            <el-form-item label="管控标准" prop="standardNo" style="width:100%">
              <el-input v-model="dataInfo.standardNo" placeholder="请输入管控标准编号" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="负责部门" prop="departId">
              <l-select v-model="dataInfo.departId" :remote="$api.departList" :all="false" all-label="--请选择--" @change="departChange('')"></l-select>
            </el-form-item>
            <el-form-item label="负责人" prop="userId">
              <l-select-employee v-model="dataInfo.userName" :departId="dataInfo.departId" code="user" @change="userChange"></l-select-employee>
            </el-form-item>
            <el-form-item label="实施部门" prop="effectDepartId">
              <l-select v-model="dataInfo.effectDepartId" :remote="$api.departList" :all="false" all-label="--请选择--" @change="departChange('effect')"></l-select>
            </el-form-item>
            <el-form-item label="实施人" prop="effectUserId">
              <l-select-employee v-model="dataInfo.effectUserName" :departId="dataInfo.effectDepartId" code="effectUser" @change="userChange"></l-select-employee>
            </el-form-item>
            <el-form-item label="备注信息" style="width:100%">
              <el-input type="textarea" rows="4" placeholder="请输入相关备注信息" v-model="dataInfo.remark"> </el-input>
            </el-form-item>
          </div>
        </el-card>
      </el-col>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">取 消</el-button>
      <el-button type="primary" @click="save">保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import lSelectEmployee from '@/views/components/l-select-employee.vue'
export default {
  components: {
    'l-select-employee': lSelectEmployee
  },
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      dataRules: {
        projectName: [{ required: true, trigger: 'blur', message: '项目名称不能为空' }],
        userId: [{ required: true, trigger: 'blur', message: '责任人不能为空' }],
        departId: [{ required: true, trigger: 'blur', message: '责任部门不能为空' }],
        effectUserId: [{ required: true, trigger: 'blur', message: '实施人不能为空' }],
        effectDepartId: [{ required: true, trigger: 'blur', message: '实施部门不能为空' }],
        requirement: [{ required: true, trigger: 'blur', message: '项目要求不能为空' }]
      }
    }
  },
  mounted() {},
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    },
    analysisTypeChange() {
      if (this.dataInfo.analysisType === 0) {
        this.dataInfo.riskLevel = 4
      } else if (this.dataInfo.analysisType === 1) {
        this.dataInfo.lec_l = 0
        this.dataInfo.lec_e = 0
        this.dataInfo.lec_c = 0
        this.calcLec()
      }
    },
    calcLec() {
      this.dataInfo.lec = this.dataInfo.lec_l + this.dataInfo.lec_e + this.dataInfo.lec_c
      if (this.dataInfo.lec < 20) this.dataInfo.riskLevel = 5
      else if (this.dataInfo.lec < 70) this.dataInfo.riskLevel = 4
      else if (this.dataInfo.lec < 160) this.dataInfo.riskLevel = 3
      else if (this.dataInfo.lec < 320) this.dataInfo.riskLevel = 2
      else this.dataInfo.riskLevel = 1
    },
    departChange(code) {
      if (code === '') {
        this.dataInfo.userId = null
        this.dataInfo.userName = null
      } else {
        this.dataInfo[code + 'UserId'] = null
        this.dataInfo[code + 'UserName'] = null
      }
    },
    userChange(item, code) {
      this.dataInfo[code + 'Id'] = item.userId
      this.dataInfo[code + 'Name'] = item.name
    },
    save() {
      this.$refs.dataInfo.validate(async valid => {
        if (valid) {
          await this.$lgh.post('api/enterprise/safely/regular/project/addoredit', this.dataInfo)
          this.visible = false
          this.onsuccess && this.onsuccess(this.dataInfo)
        }
      })
    }
  }
}
</script>

<style></style>
