<template>
  <section class="l-content">
    <el-card class="l-condition">
      <el-form :inline="true" :model="condition">
        <el-form-item label="资产分类">
          <l-select v-model="condition.categoryCode" :remote="$api.assetCategoryList" @change="getPageData"></l-select>
        </el-form-item>
        <el-form-item label="资产状态">
          <l-select v-model="condition.assetState" :data="$constant.easset_state_list" @change="getPageData"></l-select>
        </el-form-item>
        <el-form-item label="使用状态">
          <l-select v-model="condition.useState" :data="$constant.easset_usestate_list" @change="getPageData"></l-select>
        </el-form-item>
        <el-form-item label="设定状态">
          <el-radio-group v-model="condition.hasProject" size="small" @change="getPageData()">
            <el-radio-button label="false">未设定[{{ assetUnCount }}]</el-radio-button>
            <el-radio-button label="true">已设定</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.keyword" placeholder="资产编号|资产名称"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getPageData()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider></el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list">
        <template slot="assetName" slot-scope="scope">
          <el-button type="text" @click="$site.view_easset_detail(scope.row.assetId)">{{ scope.row.assetName }}</el-button>
        </template>
        <template slot="assetState" slot-scope="scope">
          <span v-if="scope.row.assetState == 1">在建</span>
          <span v-else-if="scope.row.assetState == 2">启用</span>
          <span v-else-if="scope.row.assetState == 3">封存</span>
          <span v-else-if="scope.row.assetState == 4">处置</span>
          <span v-else-if="scope.row.assetState == 5">报废</span>
        </template>
        <template slot="projectId" slot-scope="scope">
          <el-button type="warning" icon="el-icon-plus" size="mini" v-if="scope.row.projectId == null" @click="add(scope.row)">定期查设定</el-button>
          <el-button type="text" size="mini" v-else>
            <span class="text-green text-bold" style="text-decoration:underline" @click="$site.view_safely_regular_project_detail(scope.row.projectId)">已设定</span>
          </el-button>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import projectAddoredit from './_project_addoredit'

export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'SafelyRegularProject',
  data() {
    return {
      tableOption: {
        stripe: true,
        height: document.documentElement.clientHeight - this.$heightOffset1 + 'px',
        columns: [
          { label: '序号', type: 'index' },
          { label: '资产编号', prop: 'assetCode' },
          { label: '资产名称', slot: 'assetName' },
          { label: '资产分类', prop: 'categoryName' },
          { label: '品牌', prop: 'brandName' },
          { label: '规格型号', prop: 'modelName' },
          { label: '购置日期', prop: 'purchaseDate', selected: false },
          { label: '区域编号', prop: 'areaCode' },
          { label: '管理部门', prop: 'departName' },
          { label: '管理人', prop: 'userName' },
          { label: '使用部门', prop: 'useDepartName', selected: false },
          { label: '使用人', prop: 'useUserName', selected: false },
          { label: '资产状态', slot: 'assetState', filter: a => (a ? '是' : '否') },
          { label: '定期查', slot: 'projectId', width: 150 }
        ]
      },
      condition: {
        // enterpriseCode: this.userInfo.enterpriseCode,
        keyword: '',
        categoryCode: '',
        isInspected: true,
        hasProject: false,
        assetState: '',
        useState: '',
        pageIndex: 1,
        pageSize: 10
      },
      assetUnCount: null,
      departList: null,
      roleList: null,
      pageData: null
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/easset/list', ['enterpriseCode', this.condition])
      this.getEAssetUnCount()
    },
    getEAssetUnCount() {
      this.$lgh.get('api/enterprise/easset/regular/uncount', ['enterpriseCode'], false).then(res => {
        this.assetUnCount = res
      })
    },
    async add(asset) {
      await this.$lgh.openDialog({
        ins: projectAddoredit,
        title: '资产定期查设定',
        data: {
          dataInfo: {
            enterpriseCode: this.userInfo.enterpriseCode,
            assetId: asset.assetId,
            projectType: 'asset',
            projectName: asset.assetName + '_' + asset.assetCode,
            departId: asset.departId,
            userId: asset.userId,
            userName: asset.userName,
            createUser: this.userInfo.fullName,
            triggerInfo: {
              enterpriseCode: this.userInfo.enterpriseCode,
              triggerType: 'cycle'
            }
          }
        }
      })
      this.$lgh.toastSuccess('新建项目信息成功')

      this.getPageData()
    },
    edit(item) {
      this.$lgh
        .get('api/enterprise/safely/regular/project/info', {
          projectId: item.projectId
        })
        .then(async res => {
          await this.$lgh.openDialog({
            ins: projectAddoredit,
            title: '修改项目信息',
            data: {
              departList: this.departList,
              dataInfo: res
            }
          })

          this.$lgh.toastSuccess('修改项目信息成功')
          this.getPageData()
        })
    },
    del(item) {
      this.$lgh.msgConfirm('确认删除项目信息?', async () => {
        await this.$lgh.get('api/enterprise/safely/regular/project/delete', {
          projectId: item.projectId
        })
        this.$lgh.notifySuccess('删除成功', item.projectName + '项目信息已删除')
        this.getPageData()
      })
    }
  }
}
</script>
